import logoLink from '../resources/medi24.svg';
import companyLogoLink from '../resources/medi24.svg';
import footerLogoLink from '../resources/medi24_emeye-t-inside_logo.svg';

export const cssVariables = {
    background_color: 'rgb(255, 255, 255)',

    primary_light: 'rgb(153, 203, 195)',
    primary: '#4C9B8A',
    text_on_primary: 'white',
    text_on_primary_caller: 'rgb(255, 255, 255)',

    secondary: '#E53357',
    text_on_secondary: 'white',
    focus_active: '#E53357',

    font: '"Allianz Neo", Helvetica, Arial, sans-serif',
    link_color: 'rgb(0, 131, 177)',
    font_color: '#4B4B4D',
    font_weight: '400',

    border_radius: '20px',
    border_width: '2px',
    border_style: 'solid',
    border_radius_narrow: '10px',
    border_radius_super_narrow: '5px',
    border_width_nav: '8px',

    disabled: '#dbdbdb',

    gridbox_header_bg: '#787879',
    gridbox_header_text: '#fff',
    gridbox_padding: '8px',
    gridbox_header_padding: '8px',

    red: '#E53357',
    green: '#4C9B8A',
    connecting: '#c0c0c0',
    connected: '#4C9B8A',
    aborted: '#E53357',
    paused: '#EF6E00',

    header_footer_seperator: '#E53357',
    panel_highlight: '#4C9B8A',

    chat_background_right: '#F7C1CC',
    chat_background_left: '#DBDBDB',

    active: '#4C9B8A',
    inactive: '#E53357',
    text_on_active: '#fff',
    text_on_inactive: '#fff',

    dropdown_background: '#F7C1CC',

    phonebook_dropdown: '#F7C1CC',
    phonebook_dropdown_svg: '#4B4B4D',

    pointer: '#E53357',

    toggle_switch: 'rgb(255, 255, 255)',
};

export const logo = logoLink;
export const companyTitle = '';
export const companyLogo = companyLogoLink;
export const footerLogo = footerLogoLink;
